import { ThemeColor } from '../../../services/Service.types'
import { XIcon } from '@heroicons/react/outline'
import { alertConfig } from './Alert.config'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, MouseEvent } from 'react'

export type AlertProps = Omit<HTMLAttributes<HTMLDivElement>, 'color'> & {
    color?: ThemeColor
    closable?: boolean
    onClickClose?: (event: MouseEvent<HTMLButtonElement>) => void
}

const Alert = ({
    children,
    className: classNameProp,
    color = 'primary',
    closable,
    onClickClose,
    ...props
}: AlertProps) => {
    const className = buildClassesWithDefault(
        {
            alert: true,
            [alertConfig.colors[color].className]: true
        },
        classNameProp
    )

    const Icon = alertConfig.colors[color].icon

    const renderCloseIcon = () => {
        if (!closable) {
            return
        }
        return (
            <button onClick={onClickClose} className='close-button'>
                <XIcon className='icon' />
            </button>
        )
    }

    return (
        <div {...props} className={className}>
            <span>
                <Icon className='icon' />
            </span>
            <div className='relative top-1'>{children}</div>
            {renderCloseIcon()}
        </div>
    )
}

export default Alert
