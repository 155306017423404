import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type ModalTextProps = HTMLAttributes<HTMLDivElement> & {}

const ModalText = ({ children, className, ...props }: PropsWithChildren<ModalTextProps>) => {
    return (
        <div {...props} className={buildClassesWithDefault('modal-text', className)}>
            {children}
        </div>
    )
}

ModalText.displayName = 'ModalText'

export default ModalText
