import { ThemeProps } from '../theme/ThemeProps'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type ModalIconProps = HTMLAttributes<HTMLDivElement> & ThemeProps & {}

const ModalIcon = ({ children, color = 'primary', className, ...props }: PropsWithChildren<ModalIconProps>) => {
    return (
        <div {...props} className={buildClassesWithDefault({ 'modal-icon': true, [color]: true }, className)}>
            {children}
        </div>
    )
}

ModalIcon.displayName = 'ModalIcon'

export default ModalIcon
