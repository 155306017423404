import Layout, { LayoutProps } from '../Layout'
import Loading from '../../base/loading/Loading'
import LoginLayoutCarousel from './LoginLayoutCarousel'
import React, { PropsWithChildren } from 'react'
import useMiddleware from '../../../hooks/useMiddleware'
import useTheme from '../../../hooks/useTheme'

export type DefaultLayoutProps = LayoutProps & {
    subtitle?: string
}

const LoginLayout = ({ children, title = 'Some title', subtitle, ...props }: PropsWithChildren<DefaultLayoutProps>) => {
    const { isInitialized } = useMiddleware(false)
    const { getPageImage } = useTheme()

    const renderLoading = () => {
        return (
            !isInitialized && (
                <div className='w-full min-h-screen flex justify-center items-center'>
                    <Loading className='w-24 h-24' />
                </div>
            )
        )
    }

    return (
        <Layout {...props} title={title}>
            {isInitialized && (
                <div className='w-full min-h-screen flex justify-between !bg-white'>
                    <div className='w-3/6 lg:w-1/2 xl:w-[58%] h-screen relative z-20 bg-white p-6 lg:p-0 flex flex-col justify-center overflow-y-auto'>
                        <div className='flex flex-col items-center justify-center relative gap[40px]'>
                            <img className='h-28 w-auto relative left-3' src={getPageImage('logo.png')} alt='Logo' />
                            <h2 className='mt-10 text-5xl font-extrabold text-gray-600'>{title}</h2>
                            {subtitle && <p className='mt-2 text-center text-sm text-gray-600'>{subtitle}</p>}
                        </div>
                        {children}
                    </div>
                    <LoginLayoutCarousel />
                </div>
            )}
            {renderLoading()}
        </Layout>
    )
}

export default LoginLayout
