import { animated, useTransition } from '@react-spring/web'
import { useInterval } from 'usehooks-ts'
import LoginLayoutCarouselControl from './LoginLayoutCarouselControl'
import React, { useState } from 'react'
import useTheme from '../../../hooks/useTheme'

export type LoginLayoutCarouselImage = {
    id: number
    image: string
    title: string
    text: string
}

const LoginLayoutCarousel = () => {
    const { getPageImage } = useTheme()
    const [intervalReset, setIntervalReset] = useState<boolean>(false)
    const [goingToStart, setGoingToStart] = useState<boolean>(false)
    const [activeImage, setActiveImage] = useState<number>(0)

    const transition = useTransition(activeImage, {
        from: {
            position: 'absolute',
            opacity: 0,
            x: goingToStart ? -460 : 460
        },
        enter: {
            position: 'static',
            opacity: 1,
            x: 0
        },
        leave: {
            position: 'absolute',
            opacity: 0,
            x: goingToStart ? 460 : -460
        },
        config: { duration: 380 },
        trail: 380,
        initial: false
    })

    const images: LoginLayoutCarouselImage[] = [
        {
            id: 1,
            image: getPageImage('/login/carousel_1.png'),
            title: 'Realizujte objednávky <br/>s větší jednoduchostí a rychlostí.',
            text: 'Pracujeme na novém vylepšeném systému objednávek s novými funkcemi, který můžete vyzkoušet již nyní!'
        },
        {
            id: 2,
            image: getPageImage('/login/carousel_2.png'),
            title: 'Provoz se současným<br>systémem',
            text: 'Současně s novým systémem jede a je stále aktivní i ten starý, který můžete nadále využívat, jak jste zvyklí.'
        }
    ]

    useInterval(() => {
        if (intervalReset) {
            setIntervalReset(false)
            return
        }
        setActiveImage(prev => {
            if (prev === images.length - 1) {
                setGoingToStart(true)
                return 0
            }
            setGoingToStart(false)
            return prev + 1
        })
    }, 5000)

    const onChangeImage = (index: number) => {
        setGoingToStart(index < activeImage)
        setIntervalReset(true)
        setActiveImage(index)
    }

    return (
        <div className='w-3/6 lg:w-1/2 xl:w-[42%] bg-primary/5 relative z-10 p-3 overflow-x-hidden'>
            <img src={getPageImage('/login/cloud_1.svg')} alt='' className='absolute left-0 top-0 z-[1]' />
            <img src={getPageImage('/login/cloud_2.svg')} alt='' className='absolute right-0 bottom-0 z-[1]' />
            <div className='h-full relative z-20'>
                {transition((style, step) => {
                    const image = images[step]
                    return (
                        <animated.div
                            style={style as {}}
                            key={step}
                            className='flex flex-col justify-center items-center h-full'
                        >
                            <img src={image.image} alt='' className='h-[420px] object-contain' />
                            <div className='text-center text-gray-600'>
                                <h3
                                    className='font-bold text-2xl'
                                    dangerouslySetInnerHTML={{
                                        __html: image.title
                                    }}
                                />
                                <p
                                    className='font-medium text-lg mt-5 block px-10'
                                    dangerouslySetInnerHTML={{
                                        __html: image.text
                                    }}
                                />
                            </div>
                        </animated.div>
                    )
                })}
                <LoginLayoutCarouselControl images={images} activeImageIndex={activeImage} onChange={onChangeImage} />
            </div>
        </div>
    )
}

export default LoginLayoutCarousel
