import { LoginLayoutCarouselImage } from './LoginLayoutCarousel'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React from 'react'

export type LoginLayoutCarouselControlProps = {
    images: LoginLayoutCarouselImage[]
    activeImageIndex: number
    onChange: (index: number) => void
}

const LoginLayoutCarouselControl = ({ images, activeImageIndex, onChange }: LoginLayoutCarouselControlProps) => {
    const getItemClasses = (imageIndex: number) => {
        return buildClassesWithDefault(
            {
                '!bg-primary': activeImageIndex === imageIndex
            },
            'bg-white w-14 h-2 rounded-lg hover:cursor-pointer'
        )
    }

    return (
        <ol className='flex justify-center gap-2 absolute bottom-7 left-O w-full'>
            {images.map((image, index) => {
                const numberOfImage = index + 1

                return (
                    <li
                        key={image.id}
                        className={getItemClasses(index)}
                        onClick={() => {
                            onChange(index)
                        }}
                    >
                        <span className='sr-only'>{numberOfImage}</span>
                    </li>
                )
            })}
        </ol>
    )
}

export default LoginLayoutCarouselControl
