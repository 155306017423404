import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type ModalActionsProps = HTMLAttributes<HTMLDivElement> & {}

const ModalActions = ({ children, className, ...props }: PropsWithChildren<ModalActionsProps>) => {
    return (
        <div {...props} className={buildClassesWithDefault('modal-actions', className)}>
            {children}
        </div>
    )
}

ModalActions.displayName = 'ModalActions'

export default ModalActions
