import { AxiosError } from 'axios'
import { UserResetPasswordRequest } from '../../../services/api/user/UserService.types'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { userResetPassword } from '../../../services/api/user/UserService'
import parse from 'html-react-parser'
import useAxios from '../../useAxios'

export const useAdministrationUserResetPassword = () => {
    const axiosInstance = useAxios()
    const { t } = useTranslation()

    return useMutation<void, AxiosError, UserResetPasswordRequest>(
        async (request: UserResetPasswordRequest) => {
            return userResetPassword(axiosInstance, request)
        },
        {
            onSuccess: () => {
                toast.success(parse(t('dashboard.administration.forgotten_password.send_request_successful_toast')))
            }
        }
    )
}
