import React, { FormEvent, FormHTMLAttributes, ForwardedRef, KeyboardEvent, PropsWithChildren, forwardRef } from 'react'

export type FormProps = FormHTMLAttributes<HTMLFormElement> & {
    preventSubmit?: boolean
    onEnterSubmit?: (event: KeyboardEvent<HTMLFormElement>) => void
}

const Form = (
    {
        children,
        onSubmit: onSubmitProp,
        preventSubmit,
        onKeyDown,
        onEnterSubmit,
        ...props
    }: PropsWithChildren<FormProps>,
    ref: ForwardedRef<HTMLFormElement>
) => {
    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        onSubmitProp?.(event)
        preventSubmit && event.preventDefault()
    }

    const onEnter = (event: KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
            onEnterSubmit?.(event)
        }
        onKeyDown?.(event)
    }

    return (
        <form {...props} onSubmit={onSubmit} onKeyDown={onEnter} ref={ref}>
            {children}
        </form>
    )
}

export default forwardRef(Form)
