import LoginForm from './LoginForm'
import React from 'react'

const LoginCard = () => {
    return (
        <div className='login-card'>
            <div className='wrapper'>
                <LoginForm />
            </div>
        </div>
    )
}

export default LoginCard
