import { Dialog } from '@headlessui/react'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes } from 'react'

export type ModalTitleProps = HTMLAttributes<HTMLDivElement> & {}

const ModalTitle = ({ children, className, ...props }: ModalTitleProps) => {
    return (
        <Dialog.Title {...props} as='span' className={buildClassesWithDefault('modal-title', className)}>
            {children}
        </Dialog.Title>
    )
}

ModalTitle.displayName = 'ModalTitle'

export default ModalTitle
